// Copyright 2019 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Validator to check if input is float.
 */
var oppia = require('AppInit.ts').module;
oppia.filter('isFloat', [function () {
        return function (input) {
            var FLOAT_REGEXP = /(?=.*\d)^\-?\d*(\.|\,)?\d*\%?$/;
            // This regex accepts floats in the following formats:
            // 0.
            // 0.55..
            // -0.55..
            // .555..
            // -.555..
            // All examples above with '.' replaced with ',' are also valid.
            // Expressions containing % are also valid (5.1% etc).
            var viewValue = '';
            try {
                viewValue = input.toString().trim();
            }
            catch (e) {
                return undefined;
            }
            if (viewValue !== '' && FLOAT_REGEXP.test(viewValue)) {
                if (viewValue.slice(-1) === '%') {
                    // This is a percentage, so the input needs to be divided by 100.
                    return parseFloat(viewValue.substring(0, viewValue.length - 1).replace(',', '.')) / 100.0;
                }
                else {
                    return parseFloat(viewValue.replace(',', '.'));
                }
            }
            else {
                return undefined;
            }
        };
    }]);
